<template>
  <div class="expMSepelio conflex pt-3"   
       style="justify-content:center;background-color: #eee;">

    
      <div class="columna" style="width:550px">
        <div class="cab">INHUMACION / INCINERACION</div>
        <v-sheet  v-bind="$cfg.styles.marco">
          <uiText
            v-bind="$input"          
            v-model="masterCt.enterraen[2]"     
            :label="masterCt.enterraen[1]"                 
            :disabled="noEdit">                      
          </uiText>      

          <div style="display:flex">
            <uiText
              style="width:25%" 
              v-bind="$input"          
              v-model="masterCt.poliza_cpo[2]"     
              label="CP inhuma"                 
              :disabled="noEdit">                      
            </uiText>

            <ctrlF 
              style="width:75%" 
              :ct="masterCt.enterraloc"
              :edicion="!noEdit">
            </ctrlF>
          </div>

          <uiDate
            style="width:200px"
            v-model="masterCt.enterrafhhr[2]"
            :label="masterCt.enterrafhhr[1]" 
            type="datetime-local"   
            :disabled="noEdit">                      
          </uiDate>
        </v-sheet>
        
        <div class="cab">TEXTOS CINTAS/RECORDATORIOS</div>        
        <v-sheet v-bind="$cfg.styles.marco">
          <uiText
            v-bind="$input"          
            v-model="masterCt.recorda1[2]"     
            :label="masterCt.recorda1[1]"                 
            :disabled="noEdit">                      
          </uiText>

          <uiText
            v-bind="$input"          
            v-model="masterCt.recorda2[2]"     
            :label="masterCt.recorda2[1]"                 
            :disabled="noEdit">                      
          </uiText>

          <uiText
            v-bind="$input"          
            v-model="masterCt.recorda3[2]"     
            :label="masterCt.recorda3[1]"                 
            :disabled="noEdit">                      
          </uiText>          

          <uiText
            v-bind="$input"          
            v-model="masterCt.recorda4[2]"     
            :label="masterCt.recorda4[1]"                 
            :disabled="noEdit">                      
          </uiText>         
        </v-sheet>

        <!-- ESQUELAS-->
        <div class="cab">ESQUELAS</div>
        <v-sheet v-bind="$cfg.styles.marco">
          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.sepesquela[2]"
            :label="masterCt.sepesquela[1]"
            :disabled="noEdit">
          </v-checkbox>

          <uiText
            v-bind="$input"          
            v-model="masterCt.sepmodulo[2]"     
            :label="masterCt.sepmodulo[1]"                 
            :disabled="noEdit">                      
          </uiText>        
        </v-sheet>

        <!-- VELATORIO -->
        <!-- <div class="sub-row"> -->

    

        <!-- </div> -->

        <!-- MISA -->
        <!-- <div class="sub-row"> -->

 
      </div>
      <!-- </div> -->

      <div class="columna">
        <!-- TRATAMIENTO -->
        <div class="cab">VELATORIO</div>
        <v-sheet v-bind="$cfg.styles.marco">
          <uiText
            v-bind="$input"          
            v-model="masterCt.sepen[2]"     
            :label="masterCt.sepen[1]"                 
            :disabled="noEdit">                      
          </uiText>

          <ctrlF
            :ct="masterCt.seploc"
            :edicion="!noEdit">
          </ctrlF>

          <uiDate
            style="width:200px"
            v-model="masterCt.sepfhhr[2]"
            :label="masterCt.sepfhhr[1]" 
            type="datetime-local"   
            :disabled="noEdit">                      
          </uiDate>
        </v-sheet>

        <div style="display:flex">
          <!-- UD. ENTERRAMIENTO -->
          <div class="columna">
            <div class="cab">ENTERRAMTO</div>
            <v-sheet width="225px" v-bind="$cfg.styles.marco">
              <v-checkbox
                v-bind="$checkbox"
                v-model="masterCt.sepnicho[2]"
                :label="masterCt.sepnicho[1]"
                :disabled="noEdit">
              </v-checkbox>

              <v-checkbox
                v-bind="$checkbox"
                v-model="masterCt.sepsepultura[2]"
                :label="masterCt.sepsepultura[1]"
                :disabled="noEdit">
              </v-checkbox>

              <v-checkbox
                v-bind="$checkbox"
                v-model="masterCt.sepcolumbario[2]"
                :label="masterCt.sepcolumbario[1]"
                :disabled="noEdit">
              </v-checkbox>

              <v-checkbox
                v-bind="$checkbox"
                v-model="masterCt.sepcustodia[2]"
                label="cenizas familia"
                :disabled="noEdit">
              </v-checkbox>
            </v-sheet>
          </div>
          
          <div class="columna">
            <div class="cab">TRATAMIENTOS</div>
            <v-sheet width="225px" v-bind="$cfg.styles.marco">
              <v-checkbox
                v-bind="$checkbox"
                v-model="masterCt.sepembalsa[2]"
                :label="masterCt.sepembalsa[1]"
                :disabled="noEdit">
              </v-checkbox>

              <v-checkbox
                v-bind="$checkbox"
                v-model="masterCt.sepconserva[2]"
                :label="masterCt.sepconserva[1]"
                :disabled="noEdit">
              </v-checkbox>
            </v-sheet>
          </div>
        </div>

        <!-- TEMPORALIDAD -->
        <!-- <div class="columna"> -->
        <div class="cab">TEMPORALIDAD</div>
        <v-sheet width="225px" v-bind="$cfg.styles.marco">
          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.seppropiedad[2]"
            :label="masterCt.seppropiedad[1]"
            :disabled="noEdit">
          </v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.sepperpetuidad[2]"
            :label="masterCt.sepperpetuidad[1]"
            :disabled="noEdit">
          </v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.sepalquiler[2]"
            :label="masterCt.sepalquiler[1]"
            :disabled="noEdit">
          </v-checkbox>

          <uiText
            v-bind="$input"          
            v-model="masterCt.septemporal[2]"     
            :label="masterCt.septemporal[1]"                 
            :disabled="noEdit">                      
          </uiText>

          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.sepocupado[2]"
            :label="masterCt.sepocupado[1]"
            :disabled="noEdit"
          ></v-checkbox>
        </v-sheet>

        <!-- fin de display:row dentro de la columna -->

        <!-- CINTAS/RECORDATORIOS-->
 
      </div>

      <div class="columna" style="width:550px">
        <!-- OBS SEPELIO-->
        <div class="cab">MISA</div>
        <v-sheet v-bind="$cfg.styles.marco">
          <uiText
            v-bind="$input"          
            v-model="masterCt.misaen[2]"     
            :label="masterCt.misaen[1]"                 
            :disabled="noEdit">                      
          </uiText>         

          <ctrlF
            :ct="masterCt.misaloc"
            :edicion="!noEdit">
          </ctrlF>        

          <uiDate
            style="width:200px"
            v-model="masterCt.misafhhr[2]"
            :label="masterCt.misafhhr[1]" 
            type="datetime-local"   
            :disabled="noEdit">                      
          </uiDate>
        </v-sheet>

        <div class="cab">OBSERVACIONES SEPELIO</div>
        <v-sheet v-bind="$cfg.styles.marco">
          <uiObs3                       
            :record="record"
            :schema="masterCt"
            :schemaComponente="masterCt.sepobs"
            :edicion="!noEdit">                       
          </uiObs3>          
        </v-sheet>

        <!-- OBS CAMBIOS SERVICIO-->
        <div class="cab">MODIFICACION SERVICIO</div>
        <v-sheet v-bind="$cfg.styles.marco">
          <v-textarea
            v-bind="$textarea"
            v-model="masterCt.obs_ser[2]"            
            no-resize
            :disabled="noEdit">            
          </v-textarea>         
        </v-sheet>

        <!-- OBS IAP-->
        <div class="cab">IAP OBERVACIONES</div>
        <v-sheet v-bind="$cfg.styles.marco" class="mb-5 pa-2" elevation="2">
          <v-textarea
            v-bind="$textarea"
            v-model="masterCt.obs_iap[2]"           
            no-resize
            :disabled="noEdit">            
          </v-textarea>            
        </v-sheet>
      </div>
    </div>
  <!-- </div> -->
</template>



<script>  

  export default {
    components: {},
    props: {
      masterStore: { type: String, default: '' },
      masterCt: { type:Object, default:()=> {}}
    },


    created() {
      this.iniDataParticular();      
    },


    methods:{
      iniDataParticular() {
        console.log("DEV ********************** (iniDataParticular)");
        
      }


      // FUNCIONES COMUNES PARA GESTIÓN Y APDS
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      // FUNCIONES EXCLUSIVAS PARA EL MÓDULO DE GESTIÓN
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      // FUNCIONES EXCLUSIVAS PARA EL MÓDULO DE APDS
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////  
      
    },


    computed: {
      noEdit() {
        return this.$store.getters[this.masterStore + "/noEdit"];
      },

      estado() {
        return this.$store.state[this.masterStore].estado;
      },

      record() {
        return this.$store.getters[this.masterStore + "/syncRecord"];
      }
    }
  };
</script>
